import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Sections from '../components/Sections';
import Section from '../components/Section';
import Lead from '../components/Lead';
import Button from '../components/Button';
import HeroImage from '../components/HeroImage';
import SlotMachine from '../components/SlotMachine';

import styles from './services.module.scss';

const ServicesPage = ({ data, location: { pathname, state } }) => {
  const {
    markdownRemark: {
      frontmatter: { meta, components, lead, heroImage, cta, areas, slotMachine },
    },
  } = data;

  return (
    <Layout className={styles.root} pathname={pathname} meta={meta} state={state}>
      <HeroImage imageInfo={heroImage.image} alt="TODO">
        <div className={styles.heroImageContent}>
          <SlotMachine fixed={slotMachine.fixed} slots={slotMachine.slots.map(({ text }) => text)} />
          {heroImage.button ? (
            <Button inverted to={heroImage.button.url}>
              {heroImage.button.text}
            </Button>
          ) : null}
        </div>
      </HeroImage>
      <Lead {...lead} />
      <section className="section">
        <div className={`container ${styles.areas}`}>
          <div className="content" dangerouslySetInnerHTML={{ __html: areas.body }} />
          <div className={styles.circles}>
            {areas.circles.map(({ body }, i) => (
              <div key={`circle_${i}`} className="content" dangerouslySetInnerHTML={{ __html: body }} />
            ))}
          </div>
        </div>
      </section>
      <Sections text={components.body} sections={components.sections} />
      {cta && cta.body ? <Section {...cta} /> : null}
    </Layout>
  );
};

ServicesPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({
        meta: PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
        }).isRequired,
        heroImage: PropTypes.shape({
          title: PropTypes.string,
          image: PropTypes.object,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }).isRequired,
        lead: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }).isRequired,
        cta: PropTypes.shape({
          body: PropTypes.string,
          button: PropTypes.shape({
            text: PropTypes.string,
            url: PropTypes.string,
          }),
        }),
        components: PropTypes.shape({
          text: PropTypes.string,
          sections: PropTypes.arrayOf(
            PropTypes.shape({
              title: PropTypes.string,
              body: PropTypes.string,
              image: PropTypes.object,
              button: PropTypes.shape({
                text: PropTypes.string,
                url: PropTypes.string,
              }),
            }).isRequired
          ),
        }),
      }),
    }),
  }),
};

export const servicesPageQuery = graphql`
  query servicesPage {
    markdownRemark(frontmatter: { templateKey: { eq: "services-page" } }) {
      frontmatter {
        meta {
          title
          description
        }
        heroImage {
          image {
            childImageSharp {
              fluid(maxWidth: 1280, quality: 95) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          button {
            text
            url
          }
        }
        slotMachine {
          fixed
          slots {
            text
          }
        }
        lead {
          body
          button {
            text
            url
          }
        }
        cta {
          body
          button {
            text
            url
          }
        }
        areas {
          body
          circles {
            body
          }
        }
        components {
          body
          sections {
            title
            body
            button {
              text
              url
            }
          }
        }
      }
    }
  }
`;

export default ServicesPage;
