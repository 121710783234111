import React from 'react';
import PropTypes from 'prop-types';

import Section from './Section';

import styles from './Sections.module.scss';

const Sections = ({ sections, text, inverted, indent }) => (
  <div className={styles.root}>
    {text ? (
      <section className="section">
        <div className="container">
          <div className="content" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
      </section>
    ) : null}
    {sections.map((section, i) => (
      <Section indent={indent} key={`section_${i}`} inverted={inverted} odd={!((i + 1) % 2)} {...section} />
    ))}
  </div>
);

Sections.propTypes = {
  inverted: PropTypes.bool,
  indent: PropTypes.bool,
  text: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sections;
