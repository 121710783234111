import React from 'react';
import PropTypes from 'prop-types';

import Button from './Button';

import styles from './Lead.module.scss';

const Lead = ({ button, body }) => {
  return (
    <section className={`section ${styles.root}`}>
      <div className="container">
        <div className="content" dangerouslySetInnerHTML={{ __html: body }} />
        {button ? (
          <span>
            <Button to={button.url}>{button.text}</Button>
          </span>
        ) : null}
      </div>
    </section>
  );
};

Lead.propTypes = {
  body: PropTypes.string,
  button: PropTypes.shape({
    url: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default Lead;
