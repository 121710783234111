import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './SlotMachine.module.scss';

class SlotMachine extends Component {
  componentDidMount() {
    let { offsetWidth } = this.avgWidthRef;
    if (window.innerWidth <= 768) {
      offsetWidth = 0;
    } else if (window.innerWidth <= 999) {
      offsetWidth = offsetWidth * 1.75;
    }
    this.rootRef.style.marginLeft = `-${offsetWidth}px`;
  }

  render() {
    const { tag: Tag, fixed, slots, timing } = this.props;

    const sorted = slots.sort((a, b) => b.length - a.length);
    const avgWidthText = sorted[Math.floor(sorted.length / 2)];

    return (
      <Tag className={`title ${styles.root}`} ref={ref => (this.rootRef = ref)}>
        {fixed}&nbsp;
        <div>
          {slots.map((text, i) => (
            <Tag
              key={text}
              ref={avgWidthText === text ? ref => (this.avgWidthRef = ref) : undefined}
              className={`title ${styles.slots}`}
              style={{
                animationDelay: `${i * timing}s`,
                animationDuration: `${slots.length * timing}s`,
              }}
            >
              {text}
            </Tag>
          ))}
        </div>
      </Tag>
    );
  }
}

SlotMachine.propTypes = {
  fixed: PropTypes.string.isRequired,
  slots: PropTypes.arrayOf(PropTypes.string).isRequired,
  tag: PropTypes.string.isRequired,
  timing: PropTypes.number.isRequired,
};

SlotMachine.defaultProps = {
  tag: 'h1',
  timing: 2,
};

export default SlotMachine;
